import './lib/common.js'; // Активация jQuery вне Webpack, создание глобальной переменной $ 
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
import fancybox from '@fancyapps/fancybox'; // Модальные окна
// import lazyload from 'lazyload'; // Подгрузка изображений
import LazyLoad from 'vanilla-lazyload'; // Подгрузка изображений
import tooltipster from 'tooltipster'; // Подсказки
import Swiper from 'swiper'; // Карусель
// import 'animation.gsap';
// import barba from '@barba/core'; // Ajax Barba
// import './animate.js';
// import './test.js';

// Подгрузка изображений
// $('.lazyload').lazyload();

var myLazyLoad = new LazyLoad({
  elements_selector: '.lazyload',
  load_delay: 300 //adjust according to use case
});

$('#check-button').on('click', function(e) {
  e.preventDefault(); 
  var a = $('.check-list-wrapper .checkbox-input').length,
    b = $('.check-list-wrapper .checkbox-input:checked').length,
    percent = Math.round(b*1e2/a),
    btn = $(this);

  $('.check-list-wrapper .checkbox-input').addClass('disabled');
  btn.addClass('disabled');
  setTimeout(function() {
    var result = $('#check-result');
    btn.removeClass('disabled');
    btn.hide();
    result.show().addClass('show').prepend('<input type="text" name="Результат" value="' + percent + '%" hidden>');
    $('body,html').animate({scrollTop: result.offset().top}, 1500);
    $('#check-result-count').text(percent + '%');
  },2000);
});


// Анимация по скролу

// var headerHeight = $('#header').height();
// tl
//   .fromTo('.icon-logo', 5, {scale: 1, y: 0}, {scale: 5, y: 500});

// var controller = new ScrollMagic.Controller();

// var scene = new ScrollMagic.Scene({
//   duration: headerHeight,
//   offset: 100,
//   triggerElement: '#trigger'
// });
// scene.setTween(tl);
// scene.addTo(controller);



// Анимация по клику на ссылку и быстрая прогрузка страниц
// barba.init({
//   prevent: ({ el }) => el.classList && el.parentElement.classList.contains('fm-panel'),
//   transitions: [{
//     leave: function(data) {
			
			
//       // var done = this.async();
//       // tl
//       //   .fromTo('.loader', 1,{height: 0, y: h}, {y:0, height: h, ease: Power3.easeIn, onComplete: this.async()});

//     },
//     enter: function(data) {
//       window.scrollTo(0, 0);
//       // var h = window.innerHeight;
//       // swiper();
//       // scrollTop();
//       // $('.sc-trigger').removeClass('sc-trigger-active');
//       // $('.sc-nav').removeClass('sc-nav-active');
//       // $('.scroll-content').removeClass('sc-trigger-active');
//       // tl
//       //   .to('.loader', 1, {height: 0, y: 0, ease: Power3.easeOut, onComplite: function() {
//       //     scrollContent();
//       //   }});
//     }
//   }]
// });

// Modals
$('[data-fancybox]').fancybox({
  loop     : true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});

// Подсказки
$('.tooltip').tooltipster({
  animation: 'fade',
  delay: 200,
});
$('.tooltip-click').tooltipster({
  animation: 'fade',
  delay: 200,
  trigger: 'click'
});


$('.header-nav-burger').on('click', function() {
  $(this).toggleClass('is-active');
  $('.header-nav').toggleClass('is-active');
  $('body').toggleClass('menu-is-active');
  return false;
});

// Скролинг по лендингу при нажатии на ссылку
$('.scroll-to').on('click', function(e) {
  e.preventDefault();

  var id  = $(this).attr('href'),
    top = $(id).offset().top;
  $('body,html').animate({scrollTop: top}, 1500);
  $('.header-nav-burger').removeClass('is-active');
  $('.header-nav').removeClass('is-active');
  $('body').removeClass('menu-is-active');
}); 
$('.to-top').on('click', function(e) {
  e.preventDefault();
  $('body,html').animate({scrollTop: 0}, 1500);
}); 

// Градиент
var colors = new Array(
  [62,35,230],
  [230,35,98],
  [45,175,230],
  [230,0,230],
  [230,128,0]);

var step = 0;
// color table indices for: 
// current color left
// next color left
// current color right
// next color right
var colorIndices = [0,1,2,3,4];

//transition speed
var gradientSpeed = 0.002;

function updateGradient() {
	
  if ( $===undefined ) return;
	
  var c0_0 = colors[colorIndices[0]];
  var c0_1 = colors[colorIndices[1]];
  var c1_0 = colors[colorIndices[2]];
  var c1_1 = colors[colorIndices[3]];

  var istep = 1 - step;
  var r1 = Math.round(istep * c0_0[0] + step * c0_1[0]);
  var g1 = Math.round(istep * c0_0[1] + step * c0_1[1]);
  var b1 = Math.round(istep * c0_0[2] + step * c0_1[2]);
  var color1 = 'rgb('+r1+','+g1+','+b1+')';

  var r2 = Math.round(istep * c1_0[0] + step * c1_1[0]);
  var g2 = Math.round(istep * c1_0[1] + step * c1_1[1]);
  var b2 = Math.round(istep * c1_0[2] + step * c1_1[2]);
  var color2 = 'rgb('+r2+','+g2+','+b2+')';

  $('.gradient').css({
    background: '-webkit-gradient(linear, left top, right top, from('+color1+'), to('+color2+'))'}).css({
    background: '-moz-linear-gradient(left, '+color1+' 0%, '+color2+' 100%)'});
	
  step += gradientSpeed;
  if ( step >= 1 ) {
    step %= 1;
    colorIndices[0] = colorIndices[1];
    colorIndices[2] = colorIndices[3];

    //pick two new target color indices
    //do not pick the same as the current one
    colorIndices[1] = ( colorIndices[1] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;
    colorIndices[3] = ( colorIndices[3] + Math.floor( 1 + Math.random() * (colors.length - 1))) % colors.length;

  }
}

setInterval(updateGradient,10);





// Accordion
$(document).ready(function() {
  //Add Inactive Class To All Accordion Headers
  $('.accordion-header').toggleClass('inactive-header');
	
  //Set The Accordion Content Width
  // var contentwidth = $('.accordion-header').width();
  // $('.accordion-content').css({'width' : contentwidth });
	
  //Open The First Accordion Section When Page Loads
  // $('.accordion-header').first().toggleClass('active-header').toggleClass('inactive-header');
  // $('.accordion-content').first().slideDown().toggleClass('open-content');
	
  // The Accordion Effect
  $('.accordion-header').click(function() {
    let th = $(this);
    if(th.is('.inactive-header')) {
      $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
      th.toggleClass('active-header').toggleClass('inactive-header');
      th.next().slideToggle().toggleClass('open-content');
    } else {
      th.toggleClass('active-header').toggleClass('inactive-header');
      th.next().slideToggle().toggleClass('open-content');
    }
  });
	
  return false;
});




// Вставка в форму комментария от нажатой кнопки
$('.button[href="#form"]').on('click', function() {
  let product = $(this).data('product');
  $('form .hidden input[name="product"]').remove();
  $('form .hidden').append('<input type="hidden" name="product" value="'+product+'">');
});

// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// Отработка формы, проверка на пустые поля 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() {
  let th = $(this).append('<div class="utm" hidden>').find('.utm');
  $.each(params, function(key, value) {
    if (key !== 0) {
      th.append('<input name="'+key+'" value="'+value+'" hidden>');
    }
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.button-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);
      form.submit();

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send_request'});
        fbq('track','Lead');
        // yaCounter34842275.reachGoal('lead_form_order');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
      }
    });
  });
});

// Карусель
var mySwiper = new Swiper('.case-swiper', {
  init: true,
  speed: 400,
  spaceBetween: 20,
  freeMode: true,
  slidesPerView: 1,
  parallax: true,
  lazy: true,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    600: {
      slidesPerView: 1
    },
    768: {
      slidesPerView: 2
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 20
    }
  }
});


// Click Tracking
$('[data-click]').on('click', function() {
  let click = $(this).data('click');
  fbq('trackCustom',click);
  yaCounter54652267.reachGoal(click);
});
